import React, { Fragment, useState, useEffect } from "react"
import { createGlobalStyle } from 'styled-components'
import styled from "styled-components"
import backgroundGroundImg from '../images/features-bkgd.png'
import iconArrowRightOrange from '../images/icon-arrow-right-orange.svg'
import talkIcon from '../images/product_talk.png'
import { graphql, Link } from 'gatsby'
import formHtml from '../form.html'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import shareImage from '../images/share.png'

import Logo from '../components/Logo'
import Footer from '../components/Footer'

const headingFont = `'Nunito', sans-serif`
const copyFont = `'Nunito Sans' , sans-serif`
const smallScreen = `min-width: 576px`
const mediumScreen = `min-width: 768px`
const largeScreen = `min-width: 992px`
const xlargeScreen = `min-width: 1200px`

const Button = ({ className, children, ...props }) => (
  <button {...props} className={className}>
    {children}
  </button>
);

const PoweredBy = ({ className, ...props }) => (
  <div {...props} className={className}>
    Powered By:
    <Link to="https://seebot.run">
      <img src={talkIcon} alt="SeeBotTalk Icon" />
      <span>SeeBotTalk</span>
    </Link>
  </div>
)

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${copyFont};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 10px;
  }

  h1, h2, h3, h4 {
    font-family: ${headingFont};
  }

  h1 {
    font-size: 2.625rem;

    @media(${mediumScreen}) {
      font-size: 3.25rem;
    }
  }

  h2 {
    font-size: 2.185rem;

    @media(${mediumScreen}) {
      font-size: 2.625rem;
    }
  }

  h3 {
    font-size: 1.6875rem;

    @media(${mediumScreen}) {
      font-size: 2rem;
    }
  }

  .show-modal {
    pointer-events: initial !important;
    opacity: 1 !important;
  }
`

const Main = styled.main`
  border-radius: 10px;
  background: rgb(246,243,255);
  background: linear-gradient(0deg, rgba(246,243,255,1) 0%, rgba(239,235,250,1) 100%);
  position: relative;
  background-image: url(${backgroundGroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 150px;
`

const PrimaryStyledButton = styled(Button)`
  width: 250px;
  font-family: ${headingFont};
  background-color: white;
  border: 2px solid #F96900;
  border-radius: 3rem;
  font-weight: 800;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.625rem 1rem 0.5rem;
  margin: 3rem auto 0;
  outline: none;
  display: block;
  transition: background-color ease-in-out 150ms;

  ${({ disabled }) => disabled && `
    visibility: hidden;
  `}

  &:disabled {
    visibility: hidden;
  }

  &:hover {
    background-color: rgba(255, 114, 0, 0.50);
  }
`

const StyledPoweredBy = styled(PoweredBy)`
  margin-top: 1rem; 
  text-align: center;
  font-size: 0.75rem;
  color: black !important;

  a {
    color: black !important;
    text-decoration: none;

    &:visited {
      color: black !important;
    }
  }

  span {
    display: block;
    font-weight: 600;
    color: black !important;
  }

  img {
    display: block;
    margin: 0.5rem auto;
    width: 50px;
  }
`

const LinkStyledButton = styled(Button)`
  width: 250px;
  font-family: ${headingFont};
  font-weight: 800;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.625rem 1rem 0.5rem;
  margin: 1rem 0.5rem 0;
  outline: none;
  color: #F96900;
  padding: 0;
  border: 0;
  margin-top: 3rem;
  background-color: transparent;

  ${({ disabled }) => disabled && `
    visibility: hidden;
  `}

  > img {
    position: relative;
    top: 2.5px;
    margin-left: 5px;
  }

  &:disabled {
    visibility: hidden;
  }
`

const Content = styled.div`
  max-width: 768px;
  text-align: center;
  padding: 1rem;
  margin: 0 auto;

  p {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  @media(${mediumScreen}) {
    width: 75%;
  }
 `

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  margin: 2rem auto;

  @media(${mediumScreen}) {
    margin: 4rem auto;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  @media(${mediumScreen}) {
    width: 80%;
    max-width: 500px;
  }

  > div {
    height: 100%;
    padding: 1rem;

    @media(${mediumScreen}) {
      padding: 2rem;
    }
  }

  #sbr-talk {
    margin: auto;

    form {
      max-width: 700px;
      margin: auto;
    }
  }

  #sbr-talk-form-submit {
    margin-top: 0.5rem;
    text-align: center;

    #sbr-talk-form-submit-button {
      width: 100%;
      max-width: 300px;
      height: 32px;
      line-height: 1 !important;
      border: 2px solid #F96900;
      background-color: transparent;
      font-size: 0.875rem;
      font-weight: 800;
      border-radius: 32px;
      background-color: white;
      transition: background-color ease-in-out 150ms;
      
      &:hover {
        background-color: rgba(255, 114, 0, 0.50);
      }
    }
  }

  #sbr-talk-fields {
    display: flex;
    flex-wrap: wrap;

    .sbr-talk-field-group {
      flex: 100%;
      margin-bottom: 0.5rem;

      @media(${mediumScreen}) {
        flex: 50%;
        padding: 0.25rem 1rem;
      }

      &:nth-child(5) {
        flex: 100%;
      }

      &:nth-child(6) {
        flex: 100%;
        margin-top: 0 !important;
      }

      label {
        font-weight: 800;
        color: #2E2E2E;
        letter-spacing: 0.18px;
        padding: 0 1rem;
        font-size: 0.75rem !important;
        margin-bottom: 5px;
        display: block;
        text-align: left;
        width: 100%;
      }

      input[type=text],
      textarea {
        width: 100%;
        border-width: 1px;
        resize: none;
        border-color: rgba(46, 46, 46, 0.20);
        border-radius: 32px;
        padding: 0.5rem 1rem !important;
        font-weight: 300;
        color: #2E2E2E;
        height: 32px;
        line-height: 1;
        font-size: 0.75rem !important;
      }

      textarea {
        font-family: ${copyFont};
        padding: 0.75rem !important;
        height: 80px;
        border-radius: 10px;
      }

      input[type=checkbox] {
        position: relative;
        top: 3px;
        border-width: 1px;
        border-color: rgba(46, 46, 46, 0.20);
      }

      input[type=checkbox] + label {
        display: inline !important;
        padding: 0.25rem !important;
      }
    }
  }

  #sbr-talk-error-response {
    text-align: center;
    line-height: 1.5;
    border: 2px solid #DB6868;
    padding: 1rem;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 2rem;
  }

  #sbr-talk-success-response {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 600;
  }
 `

const IndexPage = ({ data }) => {
  const audioNodes = data.allContentfulAudio.edges;
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  const selectAudio = () => {
    const selectedAudio = audioNodes[Math.floor(Math.random() * audioNodes.length)].node;

    setIsFirstLoad(false);
    setAudioPlaying(false);
    setCurrentAudio(selectedAudio);
  }

  const playAudio = () => {
    if (currentAudio) {
      const audio = new Audio(currentAudio.audioFile.file.url);
      audio.play();
      audio.addEventListener('ended', () => {
        setAudioPlaying(false);
      })

      setAudioPlaying(true);
    }
  }

  useEffect(() => {
    if (currentAudio) {
      playAudio();
    }
    if(!document.getElementById('talk-script')) {
      const script = document.createElement('script'); 
      script.src = "https://talk.seebot.run/js/public.js"; 
      script.id = 'talk-script';
      document.head.appendChild(script) 
    }
  }, [currentAudio]);

  return (
    <Fragment>
      <GlobalStyle />  
      <Helmet title="Happy Holidays from SeeBotRun!">
        <link rel="icon" href={favicon} />
        <meta property="og:image" content={shareImage} />
      </Helmet>
      <Main>
        <Content>
          <Logo />
          { isFirstLoad &&
            <Fragment>
              <h2>Happy Holidays from SeeBotRun.</h2> 
              <p>2020 has been... a year or five. We hope you  have a safe and happy holiday season. Please accept our messages to you  below.  We invite you to share with us your own message of joy for others.</p>
              <p>We look forward to sharing brighter days with you in 2021.</p>
              <p>In Solidarity,
                <br />
                Dauzy, Matthew and Dana</p>
              <p><strong>Click the button below for our message to you.</strong></p>
              <PrimaryStyledButton onClick={selectAudio}>
                Continue
              </PrimaryStyledButton>
            </Fragment>
          }
          {currentAudio &&
            <Fragment>
              <h2>{currentAudio.audioText.audioText}</h2>
              <div>
                <PrimaryStyledButton onClick={selectAudio} disabled={audioPlaying}>
                  Play Another Message
                </PrimaryStyledButton>
              </div>
            </Fragment>
          }
          <FormContainer>
            <div>
              <h4>Fill out the form to join our mailing list and get a call with a special message from us.</h4>
              <div dangerouslySetInnerHTML={ {__html: formHtml} } />
              <StyledPoweredBy />
            </div>
          </FormContainer>
        </Content>
        <Footer />
      </Main>
    </Fragment>
  )
}

export default IndexPage

export const query = graphql`
  query AudioQuery {
    allContentfulAudio {
      edges {
        node {
          audioFile {
            file {
              url
            }
          }
          audioText {
            audioText
          }
        }
      }
    }
  }    
`